<template>
    <div class="user-index">
        <div class="main-h1">Обращения</div>
        <div class="card">
            <div class="card-body">
                <div class="card-body-title">Список обращений</div>
                <appeal-list :list="list" v-if="list.length"/>
                <router-link v-if="$store.state.isClaimant() || $store.state.isDefendant()" to="/panel/appeal/create_user" class="btn green">Подать обращение</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import appealList from '../../../components/appeal/list.vue';
export default {
    name: "index",
    data() {
        return {
            list: [],
        }
    },
    components: {appealList},
    methods: {
        getList() {
            this.$api.get(`appeal`).then(res => {
                if (res.data.success) {
                    this.list = res.data.data
                } else {
                    //this.$store.commit('showNotify', {title: 'Ошибка', content: res.data.error, type: 'danger'});
                }
            })
        },
    },
    created() {
        this.getList();
    }
}
</script>

<style scoped lang="scss">

</style>